<template>
    <div id="DetailedPurchasePlanning">
        <v-container fluid app>
            <v-card>
                <v-row no-gutters class="d-flex align-center justify-start mx-4 pt-2">
                    <v-col class="d-flex justify-start">
                        <div class="d-flex align-start mr-4">
                            <v-icon x-large color="primary" style="margin-top: 6px;">
                                {{ tableIcon }}
                            </v-icon>
                        </div>
                        <div class="d-flex flex-column align-start">
                            <span class="text-h6 font-weight-regular text-no-wrap">{{ tableName }}</span>
                            <div class="mb-2 d-flex align-center">
                                <v-icon small color="primary" class="mr-2">mdi-information-outline</v-icon>
                                <span class="text-caption">{{ tableDescription }}</span>
                            </div>
                        </div>
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" class="mb-2 mr-4 pa-0"
                                v-on=" on "
                                min-width="48px"
                                @click="getData()"
                                :disabled="select.length < 1 || loadingHeaders"
                            >
                                 <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Atualizar</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mb-2 mr-4 pa-0"
                                @click="openSaveDialog()"
                                v-on="on"
                                min-width="48px"
                                color="primary"
                                :disabled="data.length < 1 || loadingEvents || disableSave"
                            >
                                <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                        </template>
                        <span>Salvar</span>
                    </v-tooltip>

                    <v-dialog v-model="saveDialog" max-width="800" max-height="450" persistent>
                        <ZnapSaveDynamicReportDialog
                            v-if="saveDialog"
                            :report="report"
                            :selectedReport="selectedReport"
                            :selectedSlide="selectedSlide"
                            :filterUserCostCenterProp="filterUserCostCenter"
                            :dialog="dialog"
                            :rendererName="rendererName"
                            :aggregatorName="aggregatorName"
                            :vals="vals"
                            :columns="columns"
                            :filters="filters"
                            :valueFilter="valueFilter || {}"
                            :select="select"
                            :option="option"
                            :selectedYear="selectedYear"
                            :cols="cols"
                            :rows="rows"
                            :showTotalCol="showTotalCol"
                            :showTotalRow="showTotalRow"
                            @closeDialog="closeSaveDialog()"
                            @reportSaved="loadNewReport($event)"
                        />
                    </v-dialog>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mb-2 mr-4 pa-0"
                                @click="openDialog = true, getReports()"
                                v-on="on"
                                min-width="48px"
                                color="primary"
                            >
                                <v-icon>mdi-folder-open</v-icon>
                            </v-btn>
                        </template>
                        <span>Abrir relatório</span>
                    </v-tooltip>

                    <v-dialog v-model="openDialog" max-width="800" max-height="450">
                        <ZnapOpenDynamicReportDialog
                            v-if="openDialog"
                            :reports="reports"
                            :slides="slides"
                            @closeOpenReport="openDialog = false"
                            @getSlides="getSlides($event), selectedReport = $event"
                            @reportSelected="loadOpenReport($event)"
                        />
                    </v-dialog>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mb-2 mr-4 pa-0"
                                @click="newDialog = true"
                                v-on="on"
                                min-width="48px"
                                color="primary"
                                :disabled="!selectedReport"
                            >
                                <v-icon>mdi-folder-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Salvar novo slide</span>
                    </v-tooltip>
                    
                    <v-dialog v-model="newDialog" max-width="800" max-height="450">
                        <ZnapNewDynamicReportDialog
                            v-if="newDialog"
                            :report="report || {}"
                            :slides="slides.length || 0"
                            :rendererName="rendererName || ''"
                            :aggregatorName="aggregatorName || ''"
                            :vals="vals || []"
                            :columns="columns || []"
                            :filters="filters || []"
                            :valueFilter="valueFilter || {}"
                            :select="select || []"
                            :option="option || 'YEAR'"
                            :selectedYear="selectedYear || '0'"
                            :cols="cols || []"
                            :rows="rows || []"
                            :showTotalCol="showTotalCol || true"
                            :showTotalRow="showTotalRow || true"
                            @closeNewReport="newDialog = false, step='1'"
                            @reportSaved="loadNewReport($event), newDialog = false"
                        />
                    </v-dialog>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mb-2 mr-4 pa-0"
                                @click="copyDialog = true, getReports()"
                                v-on="on"
                                min-width="48px"
                                color="primary"
                                :disabled="!slide.id_dynamic_report_slide"
                            >
                                <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                        </template>
                        <span>Copiar slide</span>
                    </v-tooltip>

                    <v-dialog v-model="copyDialog" max-width="800" max-height="450">
                        <ZnapCopyDynamicReportDialog
                            v-if="copyDialog"
                            :reports="reports"
                            :selectedSlide="selectedSlide"
                            @closeCopyReport="copyDialog = false"
                            @getSlides="getSlides($event), selectedReport = $event"
                            @reportSelected="copyDialog = false, getReports()"
                        />
                    </v-dialog>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mb-2 mr-4 pa-0"
                                @click="reset()"
                                v-on="on"
                                min-width="48px"
                                color="primary"
                            >
                                <v-icon>mdi-file-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Gerar novo relatório</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" class="mb-2 mr-4 pa-0"
                                v-on="on"
                                min-width="48px"
                                @click="exportToExcel()"
                                :loading="loadingExport"
                            >
                                <v-icon>mdi-microsoft-excel</v-icon>
                            </v-btn>
                        </template>
                        <span>Exportar para Excel</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" class="mb-2 pa-0"
                                v-on="on"
                                min-width="48px"
                                @click="$router.push('/')"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                    </v-tooltip>

                </v-row>

                <v-divider class="mt-1 mb-2"></v-divider>

                <ZnapDynamicReportFilters  
                    :select="select"
                    :items="items"
                    :eventItems="eventItems"
                    :selects="selects"
                    :filters="filters"
                    :loadingFilters="loadingFilters"
                    :selectedYear="selectedYear"
                    :showSelectYear="showSelectYear"
                    :option="option"
                    @changeEvent="select = $event"
                    @changeOption="option = $event"
                    @changeSelectedYear="selectedYear = $event"
                    @changeFilter="filters[$event.index].selectedFilters = $event.value"
                    @changeSelect="setVisible($event)"
                />

                <!-- <v-btn class="my-4" elevation="2" @click="getData()">Refresh</v-btn> -->

                <v-divider class="mt-1 mb-4"></v-divider>

                <v-container fluid fill-height transition="fade-transition">
                    <v-layout align-center justify-center>
                        <span class="h1" v-if="data.length < 1 && !loadingEvents">
                            Selecione um ou mais eventos para gerar a tabela
                        </span>
                        <v-progress-circular
                            v-if="loadingEvents"
                            width="10"
                            size="100"
                            color="primary"
                            indeterminate
                            >
                        </v-progress-circular>
                    </v-layout>
                </v-container>
                
                <ZnapPivotTable
                    v-if="data.length > 1 && !loadingEvents"
                    :data="data"
                    :loadingEvents="loadingEvents"
                    :loading="loading"
                    :select="select"
                    :showTotalRow="showTotalRow"
                    :showTotalCol="showTotalCol"
                    :rendererName="rendererName"
                    :aggregatorName="aggregatorName"
                    :attributes="attributes"
                    :accountGroups="accountGroups"
                    :rows="rows"
                    :cols="cols"
                    :vals="vals"
                    :disabledFromDragDrop="disabledFromDragDrop"
                    :sortonlyFromDragDrop="sortonlyFromDragDrop"
                    :hiddenFromDragDrop="hiddenFromDragDrop"
                    :hiddenFromAggregators="hiddenFromAggregators"
                    @openPreview="dialog = true"
                />
                       
                <report-preview
                    :dialog="dialog"
                    :renderer="rendererName || ''"
                    :aggregator="aggregatorName || ''"
                    :vals="vals || []"
                    :headers="columns || []"
                    :filterColsList="filters || []"
                    :events="select || []"
                    :optionPreview="option || 'YEAR'"
                    :selectedYear="selectedYear || '0'"
                    :colsFields="cols || []"
                    :rowsFields="rows || []"
                    :slidesList="slides"
                    :showTotal="{col: showTotalCol, row: showTotalRow}"
                >
                </report-preview>

            </v-card>
        </v-container>
    </div>
</template>

<script>
// import { VuePivottableUi, VuePivottable, PivotUtilities, Renderer } from "vue-pivottable"
import { mapGetters } from 'vuex'
import { scaleLinear } from "d3-scale"
import ReportPreview from '../components/znap/ReportPreview'
import ZnapSaveDynamicReportDialog from '../components/znap/ZnapSaveDynamicReportDialog'
import ZnapOpenDynamicReportDialog from '../components/znap/ZnapOpenDynamicReportDialog'
import ZnapNewDynamicReportDialog from '../components/znap/ZnapNewDynamicReportDialog'
import ZnapCopyDynamicReportDialog from '../components/znap/ZnapCopyDynamicReportDialog'
import ZnapDynamicReportFilters from '../components/znap/ZnapDynamicReportFilters'
import ZnapPivotTable from '../components/znap/ZnapPivotTable'
export default {
    name: "DetailedPurchasePlanning",

components: { 
        ReportPreview,
        ZnapSaveDynamicReportDialog,
        ZnapOpenDynamicReportDialog,
        ZnapNewDynamicReportDialog,
        ZnapCopyDynamicReportDialog,
        ZnapDynamicReportFilters,
        ZnapPivotTable
    },
    // components: { VuePivottableUi, ReportPreview },

    computed: {
        ...mapGetters('auth', ['getUser']),

        endpoint() {
            return [this.$ipPurchasePlanning, "purchase-planning"]
        },

        endpointDynamicReport() {
            return [this.$ipDynamicReport, "dynamic-report"]
        },

        endpointSaveReport() {
            return [this.$ipDynamicReport, "dynamic-report-slide"]
        },

        endpointUser() {
            return [this.$ipUser, "user"]
        },

        endpointSecurity() {
            return [this.$ipSecurity, "user-group"]
        },

        reportDescriptionHint() {
            let hint = ''

            if (this.verifiedReportDescription !== 0 && this.report.description.length > 0) {
                if (this.verifiedReportDescription === -1) {
                    hint = "Está descrição já está em uso."
                } else if (this.verifiedReportDescription === 0 && this.report.description.length > 0) {
                    hint = "Verifique novamente a descrição"
                } else {
                    hint = "Tudo certo!"
                }
            } else {
                hint = ''
            }

            return hint
        },
        

        disableSaveReportButton() {
            let disabled = false

            if ((this.step === '1' || this.step === 1) && (!this.report.description.length || this.verifiedReportDescription !== 1)) {
                disabled = true
            }

            if ((this.step === '2' || this.step === 2) && (!this.report.number || !this.report.title)) {
                disabled = true
            }

            return disabled
        },

        eventItems() {
            let eventItems
            let eventOptions = this.items

            if (this.select.length) {
                let selectedEvent = eventOptions.find(i => i.id === this.select[0])
                eventItems = eventOptions.filter(e => e.id_account_group_version === selectedEvent.id_account_group_version)
            } else {
                eventItems = eventOptions
            }
            
            return eventItems
        }
    },

    data: () => ({
        tableName: "Construtor de relatórios dinâmicos",
        tableIcon: "mdi-table-large",
        tableDescription: "Forneça uma descrição para esta tabela.",
        tableRows: 100000,

        noDataMessage: "",
        noData: true,

        select: [],
        selectFirst: null,
        selectSecond: null,
        items: [],
        id_account_group_version: 0,

        columns: [],
        columnsBiYear: [],
        columnsYear: [],
        columnsYearMonth: [],

        loadingEvents: false,
        loadingVals: false,
        loadingExport: false,
        dialog: false,
        labelClass: [
            'position: absolute; margin-top: 375px;',
            true,
            false
        ],

        saveDialog: false,
        openDialog: false,
        copyDialog: false,
        newDialog: false,

        selectedReport: null,
        selectedSlide: null,

        zoom: false,

        headers: [
            {
                text: 'Nome', value: 'nome' 
            }
        ],
        reports: [],
        slides: [],

        option: "YEAR",
        operator: null,
        operation: "Nenhuma",
        operations: ["- (Diferença)", "% (Percentual)", "Nenhuma"],
        filterDialog: false,
        valueFilter: {},

        selectedView: '',
        selectedVals: ['', ''],
        selectedCols: [],
        selectedRows: [],

        users: [],
        admins: [],
        selectAdmin: [],
        selectUser: [],
        selectAdminUnchanged: [],
        selectUserUnchanged: [],
        adminUserGroup: null,
        userGroup: null,
        check: true,

        step: "1",
        page: "0",
        slideTime: "5000",
        time: [
            {
                text: "Estático",
                time: "0"
            },
            {
                text: "5 segundos",
                time: "5000"
            },
            {
                text: "15 segundos",
                time: "15000"
            },
            {
                text: "30 segundos",
                time: "30000"
            },
        ],

        descriptionCompare: "",

        report: {
            id: null,
            description: "",
            number: null,
            title: null,
            text: null,
            format: null,
            pivotTable: {
                option: null,
                operation: null,
                renderer: null,
                aggregator: null,
                vals: [],
                headers: [],
                cols: [],
                rows: [],
                filters: []
            }
        },

        slide: {},
        reportOpen: {},
        loadingCheckDescription: false,
        checkedDescription: '',

        config: {},
        filteredData: [],
        showTotalRow: true,
        showTotalCol: true,
        data: [],
        dataRows: [],
        attributes: [],
        rows: ["Conta gerencial"],
        cols: ["Nome do evento"],
        vals: ["Total Ano"],
        valsHeight: null,
        disabledFromDragDrop: [],
        hiddenFromDragDrop: [],
        hiddenFromAggregators: [],
        sortonlyFromDragDrop: [],
        pivotColumns: [],
        loading: false,
        aggregatorName: "Sum",
        rendererName: "Table",
        dataSourceSettings: {  
            showGrandTotals: false,
            showSubTotals: false,
        },
        loadingHeaders: false,
        accountGroups: [],
        yearMonths: [
            'Jan/20', 'Fev/20', 'Mar/20', 'Abr/20', 'Mai/20', 'Jun/20', 'Jul/20', 'Ago/20', 'Set/20', 'Out/20', 'Nov/20', 'Dev/20',
            'Jan/21', 'Fev/21', 'Mar/21', 'Abr/21', 'Mai/21', 'Jun/21', 'Jul/21', 'Ago/21', 'Set/21', 'Out/21', 'Nov/21', 'Dev/21',
            'Jan/22', 'Fev/22', 'Mar/22', 'Abr/22', 'Mai/22', 'Jun/22', 'Jul/22', 'Ago/22', 'Set/22', 'Out/22', 'Nov/22', 'Dev/22',
            'Jan/23', 'Fev/23', 'Mar/23', 'Abr/23', 'Mai/23', 'Jun/23', 'Jul/23', 'Ago/23', 'Set/23', 'Out/23', 'Nov/23', 'Dev/23'
        ],
        periodMonths: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],

        //save reports update
        verifiedReportDescription: 0,
        savingReport: false,

        //tabs refactor
        tab: 0,
        filters: [],
        loadingFilters: [],
        selects: [],

        disableSave: false,

        showSelectYear: false,
        selectedYear: '1',
        
        filterUserCostCenter: false,
    }),

    created () {
        this.getEvents()
        this.callHeaders()
        // this.getUsers()
        
        this.$root.$on('closeDialog', () => {
            this.dialog = false
        })
        this.$root.$on('getView', ($event) => {
            this.selectedView = $event
            this.rendererName = this.selectedView
        })
        this.$root.$on('changeVal', ($event) => {
            this.aggregatorName = $event
            // this.selectedVals[0] = ($event)
            if (
                $event === 'Count' ||
                $event === 'Count as Fraction of Total'||
                $event === 'Count as Fraction of Rows' ||
                $event === 'Count as Fraction of Columns'
            ) {
                this.labelClass = [
                    'position: absolute; margin-top: 330px;',
                    false,
                    false
                ]
            }
            else if ($event === 'Sum over Sum') {
                this.labelClass = [
                    'position: absolute; margin-top: 415px;',
                    true,
                    true
                ]
            }
            else {
                this.labelClass = [
                    'position: absolute; margin-top: 375px;',
                    true,
                    false
                ]
            }
        })

        this.$root.$on('getVals', ($event) => {
            $event[0] === 0 ? this.selectedVals[0] = $event[1] : this.selectedVals[1] = $event[1]
            this.vals = this.selectedVals
        })

        this.$root.$on('getCol', ($event) => {
            if ($event[1] === 1) {
                let val = []
                this.selectedCols.forEach((col) => {
                    if (col != $event[0]) {
                        val.push(col)
                    }
                })
                this.selectedCols = val
            } else {
                this.selectedCols.push($event[0])
            }
            // alert(this.selectedCols)
        })
        this.$root.$on('getRow', ($event) => {
            if ($event[1] === 1) {
                let val = []
                this.selectedRows.forEach((col) => {
                    if (col != $event[0]) {
                        val.push(col)
                    }
                })
                this.selectedRows = val
            } else {
                this.selectedRows.push($event[0])
            }
            // alert(this.selectedRows)
        })

        this.$root.$on('getValueFilter', ($event) => {
            this.valueFilter = $event
        })

        // Esse evento deve ser emitido ao carregar um relatório já salvo
        // this.$root.$emit('setValueFilter', this.valueFilter)
    },

    mounted () {
        if(this.$route.query.report) {
            this.loadSlideToEdit()
        }
    },

    methods: {
        colorScaleGenerator(values) {
            const scale = scaleLinear()
                .domain([0, Math.max.apply(null, values)])
                .range(["#fff", "#88f"])
            return (x) => {
                return { "background-color": scale(x) }
            }
        },

        
        reset () {
            Object.assign(this.$data, this.$options.data());
            this.slides = []
            this.getEvents()
            this.callHeaders()
            // this.getUsers()
            this.getReports()
            this.disableSave = false
        },

        async callHeaders() {
            // await this.getHeaders('BI_YEAR')
            await this.getHeaders('YEAR')
            await this.getHeaders('YEAR_MONTH')
            this.columns = this.columnsYear
            if (this.selectedSlide) this.columns =  this.slide.pivot_params_json.headers
        },

        async getHeaders(option) {
            try {
                this.loadingHeaders = true
                const payload = {
                    queryType: option,
                    showCommonHeaders: true
                }
                
                let res = await this.$http.post(
                    this.endpoint[0] + this.endpoint[1] + "/analyse-headers",
                    payload
                )
                if (res) {
                    /* res.data.headers.forEach((column) => {
                        let item = {}
                            if (column.order) {
                                item = {
                                    name: column.name,
                                    visible: column.visible,
                                    order: column.order
                                }
                            } else {
                                item = {
                                    name: column.name,
                                    visible: column.visible,
                                }
                            }
                            this.columns.push(item)
                    }) */
                    // if (option === 'BI_YEAR') {
                    //     this.columnsBiYear = res.data.headers
                    // }
                    if (option === 'YEAR') {
                        this.columnsYear = res.data.headers
                    }
                    if (option === 'YEAR_MONTH') {
                        this.columnsYearMonth = res.data.headers
                    }
                    this.loadingHeaders = false
                    // this.option = option
                    // this.columns = res.data.headers
                }
            } catch (err) {
                this.loadingHeaders = false
                this.$fnError(err)
            }
        },

        async getData() {
            this.loadingEvents = true
            this.loadingVals = true

            let yearReference = parseInt(this.selectedYear)

            const filterConditions = []
            for (let cont = 0; cont < this.filters.length; cont++) {
                let filter = this.filters[cont]
                if (filter.selectedFilters.length > 0) {
                    let values = []
                    
                    values = [...filter.selectedFilters]

                    filterConditions.push({
                        AndOr: "AND",
                        column: filter.columnId,
                        operator: "IN",
                        value: values
                    })
                }
            }

            if (this.selectFirst) {
                this.select = this.selectSecond != null ? this.select = [this.selectFirst, this.selectSecond]
                                                : this.select = [this.selectFirst]
            }
            try {
                const payload = {
                    id_account_group_version: this.id_account_group_version,
                    events: [...this.select],
                    NoFormulas: true,
                    columns: this.columns,
                    calculateDifference: this.operator,
                    ResultInArray: true,
                    queryType: this.option,
                    yearReference: yearReference,
                    filter: {
                        page: 0,
                        tableRows: 100000,
                        conditions: filterConditions,
                        having: [],
                    },
                }
                
                if (this.selectedReport) payload.id_dynamic_report = this.selectedReport

                let res = await this.$http.post(
                    this.endpoint[0] + this.endpoint[1] + "/analyse",
                    payload
                )
                if (res) {
                    this.dataRows = res.data.rows
                    this.data = this.dataRows
                    this.pivotColumns = this.columns
                    this.disabledFromDragDrop = []
                    this.hiddenFromDragDrop = []
                    this.attributes = []

                    for (let cont = 0; cont < this.columns.length; cont++) {
                        let column = this.columns[cont]
                        if (column.order === undefined || column.visible === false) {
                            this.hiddenFromDragDrop.push(column.text)
                        } else {
                            this.attributes.push(column.text)
                        }
                        if (!column.valueColumn) {
                            this.hiddenFromAggregators.push(column.text)
                        }
                    }
                    this.loadingEvents = false
                }
            } catch (err) {
                this.loadingEvents = false
                this.loadingVals = false
                this.$fnError(err)
            }
        },

        async getEvents() {
            try {
                const payload = {
                    filter: {
                        orderColumn: 'description',
                        conditions: [
                            {
                                AndOr: 'AND',
                                column: 'id_module',
                                operator: '=',
                                value: 6
                            }
                        ]
                    }
                }
                let res = await this.$http.post(
                    this.$ipEvent + "event" + "/list-options",
                    payload
                )
                if (res) {
                    this.items = res.data.rows
                    if(res.data.rows.length > 0) this.id_account_group_version = res.data.rows[0].id_account_group_version
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        openSaveDialog() {
            !this.selectedReport ? this.report.number = 1 : false
            this.saveDialog = true
            this.getUsers()
        },

        closeSaveDialog() {
            this.savingReport = false
            this.saveDialog = false
            this.step = '1'
            //para impedir que o close limpe caso já tenha report aberto
            if (!this.report.id){
                this.selectAdmin = []
                this.selectUser = []
                this.admins = []
                this.users = []
                this.report = {
                    id: null,
                    description: "",
                    number: null,
                    title: null,
                    text: null,
                    format: null,
                    pivotTable: {}
                }
                this.verifiedReportDescription = 0
            }
        },

        async getUsers() {
            try {
                const res = await this.$http.post(this.$ipUser + "user/list", {})
                if (res) {
                    let users = res.data.rows.sort((a, b) => {
                        let nameA = a.name.toLowerCase(),
                            nameB = b.name.toLowerCase()

                        if (nameA < nameB) return -1
                        if (nameA > nameB) return 1
                        return 0 
                    })

                    if (!this.selectedReport && !this.$route.query.report) {
                        const currentUser = users.find(u => u.id_user === this.getUser.id_user)
                        this.users = users.filter(u => u.id_user !== currentUser.id_user)
                        this.selectAdmin.push(currentUser.id_user)
                    } else {
                        this.users = users
                    }
                    this.admins = users
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        changeSelectedAdmin(e) {
            if (!e.includes(this.getUser.id_user)){
                this.selectAdmin.push(this.getUser.id_user)
                this.$toast.error('O criador do relatório deve ser um Admin')
            }
        },

        async listUserGroup (report) {
            
            this.selectAdmin = []
            this.selectUser = []

            const payload = {
                filter: {
                    conditions:[
                        {
                            AndOr: "AND",
                            column: "id_dynamic_report",
                            operator: "=",
                            value: report,
                        }
                    ]
                }
            }

            try {
                let res = await this.$http.post(
                    this.endpointDynamicReport[0] + "dynamic-report-user-group" + "/list-user",
                    payload
                )
    
                if (res) {
                    let users = res.data.rows
                    users.forEach((user) => {
                        if (user.admin === 1) {
                            this.selectAdmin.push(user)
                            this.adminUserGroup = user.id_group
                        } else {
                            this.selectUser.push(user)
                            this.userGroup = user.id_group
                        }
                    })
                    this.selectAdminUnchanged = this.selectAdmin
                    this.selectUserUnchanged = this.selectUser
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async getReports () {
            try {
                const payload = {
                    filter: {
                        conditions: [
                            {
                                AndOr: "AND",
                                column: "id_module",
                                operator: "=",
                                value: 6,
                            },
                            {
                                AndOr: "AND",
                                column: "report_type",
                                operator: "=",
                                value: 0
                            }
                        ]
                    }
                }

                let res = await this.$http.post(
                    this.endpointDynamicReport[0] + 'dynamic-report' + "/list",
                    payload
                )
                if (res) {
                    this.reports = res.data.rows
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async getSlides (report) {
            this.slides = []
            try {
                const payload = {
                    filter: {
                        page: 0,
                        tableRows: 10000,
                        conditions: [
                            {
                                AndOr: "AND",
                                column: "id_dynamic_report",
                                operator: "=",
                                value: report
                            }
                        ]
                    }
                }

                let res = await this.$http.post(
                    this.endpointDynamicReport[0] + 'dynamic-report-slide' + "/list",
                    payload
                )
                if (res) {
                    this.slides = res.data.rows
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async setSlide () {
            this.reports.forEach((item) => {
                item.id_dynamic_report == this.selectedReport ? this.reportOpen = item : false
            })
            if (this.reportOpen) this.filterUserCostCenter = this.reportOpen.filter_user_cost_center === 1 ? true : false
            this.slides.forEach((slide) => {
               if (typeof slide.pivot_params_json === "string") {
                    slide.pivot_params_json = JSON.parse(slide.pivot_params_json)
                }
                slide.id_dynamic_report_slide == this.selectedSlide ? this.slide = slide : false
            })

            this.descriptionCompare = this.slide.dynamic_report
            this.report = {
                id: this.selectedReport,
                description: this.slide.dynamic_report,
                number: this.slide.slide_sequence,
                title: this.slide.slide_title,
                text: this.slide.slide_text,
                format: this.slide.slide_css,
                pivotTable: this.slide.pivot_params_json
            }

            this.tableName = this.slide.slide_title
            this.tableDescription = this.slide.dynamic_report
            this.select = this.slide.pivot_params_json.events
            
            this.items.forEach((item) => {
                if (item.id === this.select[0]) {
                    this.id_account_group_version = item.id_account_group_version
                }
            })
            this.columns =  this.slide.pivot_params_json.headers
            this.filters = this.slide.pivot_params_json.filterColumn
            this.rendererName = this.slide.pivot_params_json.renderer
            this.aggregatorName = this.slide.pivot_params_json.aggregator
            this.cols = this.slide.pivot_params_json.cols
            this.rows = this.slide.pivot_params_json.rows
            this.option = this.slide.pivot_params_json.option
            this.selectedYear = this.slide.pivot_params_json.selectedYear
            this.operation =  this.slide.pivot_params_json.operation
            this.vals =  this.slide.pivot_params_json.vals
            this.showTotalRow = this.slide.pivot_params_json.showTotalRow
            this.showTotalCol = this.slide.pivot_params_json.showTotalCol

            this.filters.forEach((filter) => {
                if (filter.selectedFilters.length > 0) {
                    this.showFilterItems(filter)
                    this.filterIsLoading(filter)
                }
            })
            this.selects = this.columns.filter(col => col.select)
            await this.getData()
            this.valueFilter = this.slide.pivot_params_json.filters
            this.$root.$emit('setValueFilter', this.slide.pivot_params_json.filters)

            await this.listUserGroup(this.selectedReport)

            const adminArray  = this.selectAdmin.find((admin) => admin.id_user === this.getUser.id_user)
            adminArray ? this.disableSave = false : this.disableSave = true
        },

        async loadSlideToEdit () {
            this.selectedReport = this.$route.query.report
            this.selectedSlide = this.$route.query.slide
            await this.getUsers()
            await this.listUserGroup(this.$route.query.report)
            await this.getReports()
            await this.getSlides(this.$route.query.report)
            await this.setSlide()
            const adminArray  = this.selectAdmin.find((admin) => admin.id_user === this.getUser.id_user)
            adminArray ? this.saveDialog = true : this.disableSave = true
        },

        async exportToExcel () {
            this.loadingExport = true
            let conditions = []
            this.filters.forEach((column) => {
                if (column.selectedFilters.length > 0) {
                    let condition = {
                        AndOr: "AND",
                        column: column.columnId,
                        operator: "IN",
                        value: column.selectedFilters
                    }
                    conditions.push(condition)
                }
            })
            try {
                const payload = {
                    id_account_group_version: this.id_account_group_version,
                    events: [...this.select],
                    NoFormulas: true,
                    columns: this.columns,
                    calculateDifference: false,
                    ResultInArray: true,
                    queryType: this.option,
                    filter: {
                        conditions: conditions
                    }
                }

                let res = await this.$http.post(
                    this.endpoint[0] + this.endpoint[1] + "/report-analyse",
                    payload
                )
                if (res) {
                    this.loadingExport = false
                    window.open(res.data.token)
                }
            } catch (err) {
                this.loadingExport = false
                this.$fnError(err)
            }
        },

        //tab refactor

        async showFilterItems(filter) {
            if (filter?.items?.length < 1) {
                this.loadingFilters.push(filter.name)

                if (filter.columnId === 'id_cost_center' && this.selectedReport || filter.columnId === 'id_product' && this.selectedReport ) {
                    filter.id_dynamic_report = this.selectedReport
                }

                try {
                    let isCategoryParent = filter.columnId === 'id_product_category_parent' ? true : false
                    let payload = { isCategoryParent, filter: { conditions: filter.conditions } }
                    if (filter.columnId === 'id_cost_center' && this.selectedReport || filter.columnId === 'id_product' && this.selectedReport ) {
                        payload.id_dynamic_report = this.selectedReport
                    }
                    let res = await this.$http.post(filter.server + '/list-options', payload)
                    // let res = await this.$http.post(filter.local + '/list-options', {})
                    if (res) {
                        let foundFilter = this.filters.find(f => f.columnId === filter.columnId)
                        foundFilter.items = res.data.rows

                        this.loadingFilters.pop()
                    }
                } catch (err) {
                    this.loadingFilters.pop()
                    this.$fnError(err)
                }
            }
        },

        filterIsLoading(filter) {
            if (this.loadingFilters.includes(filter.name)) {
                return true
            } else {
                return false
            }
        },

        setVisible (item) {
            let index = this.columns.findIndex(column => column.name === item.name)
            this.columns[index].visible = item.visible
        },

        async verifyReportDescription (){
            let payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "dynamic_report",
                            operator: "=",
                            value: `${this.report.description}`
                        }
                    ]
                }
            }

            if (!this.report.id) {
                this.loadingCheckDescription = true
                try {
                    const res = await this.$http.post(this.$ipDynamicReport + 'dynamic-report/list-options', payload)
                    if (res) {
                        if (res.data.rows.length > 0) {
                            this.loadingCheckDescription = false
                            return this.verifiedReportDescription = -1
                        } else {
                            this.checkedDescription = this.report.description
                            this.loadingCheckDescription = false
                            return this.verifiedReportDescription = 1
                        }
                    }
                } catch (err) {
                    this.loadingCheckDescription = false
                    this.$fnError(err)
                }
            } else {
                return this.verifiedReportDescription = 1
            }
        },

        async loadNewReport (ids) {
            this.selectedReport = ids.id_report
            this.selectedSlide = ids.id_slide
            this.getSlides(ids.id_report).then( async () => {
                await this.getReports()
                this.setSlide()
            })
            this.closeSaveDialog()
        },

        loadOpenReport (id) {
            this.selectedSlide = id
            this.setSlide()
            this.openDialog = false
        }
    },

    watch: {
        select (val) {
            let event = {}
            this.items.forEach((item) => {
                if (item.id === val[0]) {
                    event = item
                    this.id_account_group_version = item.id_account_group_version
                }
            })
            this.callHeaders(this.option).then(() => {
                this.filters.forEach((filter) => {
                    if (filter.conditions) {
                        filter.conditions.forEach((condition) => {
                            if (event[condition.column]) {
                                condition.value = event[condition.column]
                            }
                        })
                    }
                })
                this.showFilterItems(this.filters.find(filter => filter.columnId == "id_account_group")).then(() => {
                    let filter = this.filters.find(filter => filter.columnId == "id_account_group") || { items: [] }
                    let filteredItems = filter.items.sort((a, b) => {
                        return (a.account_order > b.account_order) ? 1 : ((b.account_order > a.account_order) ? -1 : 0);
                    })
                    let accountTexts = []
                    filteredItems.forEach((filter => {
                        accountTexts.push(filter.text)
                    }))
                    this.accountGroups = accountTexts
                })
            })
        },

        //tab refactor test
        columns: {
            immediate: true,
            deep: true,
            handler (val) {
                if (!this.report.id){
                    this.filters = []
                    this.selects = []
                    val.forEach((column) => {
                        if (column.filter) {
                            column.items = []
                            column.selectedFilters = []
                            this.filters.push(column)
                        }
                    })
                    val.forEach((column) => {
                        if (column.select) {
                            this.selects.push(column)
                        }
                    })
                }
            }
        },
        
        //CAUSANDO ERRO AO RESETAR VUE, FILTEREDDATA NÃO USADO EM OUTRO LUGAR
        // config: {
        //     // eslint-disable-next-line no-unused-vars
        //     handler(value, oldValue) {
        //         const PivotData = PivotUtilities.PivotData
        //         this.data = this.dataRows
        //         this.filteredData = new PivotData(value).getFilteredData()
        //     },
        //     deep: true,
        //     immediate: false,
        // },

        async option (value) {
            // if (value === 'BI_YEAR') {
            //     this.columns = this.columnsBiYear
            //     this.vals = ["Total Ano Ano 1"]
            if (value === 'YEAR') {
                this.columns = this.columnsYear
                this.vals = ["Total Ano"]
                this.showSelectYear = false
            } else {
                this.columns = this.columnsYearMonth
                this.vals = ["Valor"]
                this.showSelectYear = true
            }
            // await this.getHeaders()
            // await this.getData()
        },

        operation (value) {
            if (value == "- (Diferença)") {
                this.operator = "-"
            } else if (value == "% (Percentual)") {
                this.operator = "%"
            } else {
                this.operator = null
            }
            // this.getData()
        },

        step (value) {
            if (value === 3) {
                this.report.id ? this.listUserGroup(this.report.id) : false
            }
        },

        page (value) {
            this.tableName = this.slides[value].slide_title
            this.tableDescription = this.slides[value].dynamic_report
            this.select = this.slides[value].pivot_params_json.events
            this.columns =  this.slides[value].pivot_params_json.headers
            this.filters = this.slides[value].pivot_params_json.filterColumn
            this.rendererName = this.slides[value].pivot_params_json.renderer
            this.aggregatorName = this.slides[value].pivot_params_json.aggregator
            this.cols = this.slides[value].pivot_params_json.cols
            this.rows = this.slides[value].pivot_params_json.rows
            this.option = this.slides[value].pivot_params_json.option
            this.selectedYear = this.slides[value].pivot_params_json.selectedYear
            this.operation =  this.slides[value].pivot_params_json.operation
        }
    },
}
</script>

<style src="@/../public/styles/pivottable/pivottable.full.css"/>