<template>
  <v-card style="position: relative;">
      <v-toolbar
          dark
          flat
          :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
          style="position: sticky; top: 0; width: 100%; z-index: 1000;" 
      >
          <v-toolbar-title>Assistente de relatório dinâmico</v-toolbar-title>
          <v-spacer />

          <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                  <v-btn color="transparent" class="mb-2 mt-2"
                      v-on="on"
                      min-width="48px"
                      @click="$emit('closeDialog')"
                      depressed
                  >
                      <v-icon class="white--text">mdi-close</v-icon>
                  </v-btn>
              </template>
              <span>Fechar</span>
          </v-tooltip>
      </v-toolbar>
      <v-stepper v-model="step" elevation="0">
          <v-stepper-header>
              <v-stepper-step :complete="step > 1" step="1" >
                  Descrição do relatório
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 2" step="2">
                  Descrição do slide
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="3">
                  Permissões de grupo de usuários
              </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>

              <v-stepper-content step="1">
                  <v-layout justify-center align-center wrap>
                      <v-flex xs9>
                          <v-text-field
                              ref="reportDescriptionInput"
                              type="text"
                              class="mx-2 mb-2"
                              label="Descrição do relatório *"
                              :hint="reportDescriptionHint"
                              :error-messages="verifiedReportDescription === -1 ? 'Esta descrição já está em uso' : verifiedReportDescription === -2 ? 'Verifique novamente a descrição' : ''"
                              persistent-hint
                              :value="reportSave.description"
                              @input=" v => {reportSave.description = v, reportSave.description !== checkedDescription && this.verifiedReportDescription === 1 ? this.verifiedReportDescription = -2 : false}"
                          ></v-text-field>
                      </v-flex>
                      <v-flex xs3>
                          <v-btn
                              mt-2
                              small
                              color="primary"
                              @click="verifyReportDescription()"
                              :loading="loadingCheckDescription"
                          >
                              <span> Verificar descrição </span>
                          </v-btn>
                      </v-flex>
                  </v-layout>
              </v-stepper-content>

              <v-stepper-content step="2">
                  <div class="ml-2">
                      <span class="text-caption">Descrição do relatório: </span>
                      <span class="text-subtitle-1 font-weight-bold">{{ report.description }}</span>
                  </div>
                  <v-layout align-center justify-center wrap class="my-4">
                      <v-flex xs12>
                          <v-text-field
                              :value="reportSave.number"
                              @input=" v => reportSave.number = v"
                              max-length="11"
                              class="mx-2 my-1"
                              type="number"
                              label="Número sequencial do slide"
                          ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                          <v-text-field
                              :value="reportSave.title"
                              @input=" v => reportSave.title = v"
                              max-length="250"
                              class="mx-2 my-1"
                              label="Título do slide"
                          ></v-text-field>
                      </v-flex>
                      <!-- <v-flex xs12>
                          <v-text-field
                              :value="report.text"
                              @input=" v => report.text = v"
                              class="mx-2 my-1"
                              label="Texto estático do slide"
                          ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                          <v-text-field
                              :value="report.format"
                              @input=" v => report.format = v"
                              class="mx-2 my-1"
                              label="Formato do slide"
                          ></v-text-field>
                      </v-flex> -->
                  </v-layout>
                  <v-divider class="mt-1 mb-1"></v-divider>
              </v-stepper-content>

              <v-stepper-content step="3">
                  <v-layout align-center justify-center wrap>
                      <v-flex xs12 class="mb-4">
                          <p>Grupo de usuários para acesso</p>
                      </v-flex>
                      <v-flex xs12>
                          <v-autocomplete
                              :value="selectAdmin"
                              @input=" v => selectAdmin = v"
                              :items="admins"
                              class="my-4"
                              item-text="name"
                              item-value="id_user"                
                              label="Admin"
                              @change="changeSelectedAdmin($event)"
                              multiple
                              chips
                              hint="Selecione os usuários que podem visualizar e editar o relatório."
                              persistent-hint
                          ></v-autocomplete>
                      </v-flex>
                      <v-flex xs12>
                          <v-autocomplete
                              :value="selectUser"
                              @input=" v => selectUser = v"
                              :items="users"
                              class="my-4"
                              item-text="name"
                              item-value="id_user"                
                              label="Usuários"
                              multiple
                              chips
                              hint="Selecione os usuários que podem apenas visualizar o relatório."
                              persistent-hint
                          ></v-autocomplete>
                      </v-flex>
                      <v-flex xs12>
                          <v-checkbox
                              v-model="filterUserCostCenter"
                              :label="'Aplicar filtro para  usuário ver apenas os centros de custos ou projetos/produtos permitidos'"
                              class="mx-2"
                          />
                      </v-flex>
                  </v-layout>
                  <v-divider class="mt-1 mb-2"></v-divider>
              </v-stepper-content>
          </v-stepper-items>
      </v-stepper>
      <div class="light" style="position: sticky; bottom: 0; width: 100%;">
          <v-divider class="mx-4"></v-divider>
          <v-card-actions class="px-6 py-6">
              <span class="text-caption primary--text ml-3">* Campos obrigatórios</span>
              <v-spacer></v-spacer>
              <v-btn text class="mr-4"
                  color="primary"
                  @click="step > 1 ? step-- : $emit('closeDialog')"
                  :ripple="false"
              >
                  <span v-if="step === '1' || step === 1">Cancelar</span>
                  <span v-else>Voltar</span>
              </v-btn>
              <v-btn color="primary"
                  @click="step < '3' ? step++ : saveReport()"
                  :disabled="disableSaveReportButton"
                  :loading="savingReport"
              >
                  <span v-if="step < 3"> Continuar </span>
                  <span v-else> Salvar </span>
              </v-btn>
          </v-card-actions>
      </div>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
      report : {
        type: Object,
        required: true
      },
      selectedReport: {
        type: Number,
        required: false
      },
      selectedSlide: {
        type: Number,
        required: false
      },
      filterUserCostCenterProp: {
        type: Boolean,
        required: true
      },
      dialog : {
        type: Boolean,
        required: true
      },
      option: {
          type: String,
          required: true
      },
      selectedYear: {
          type: String,
          required: true
      },
      cols: {
          type: Array,
          required: true
      },
      rows: {
          type: Array,
          required: true
      },
      rendererName: {
          type: String,
          required: true
      },
      aggregatorName: {
          type: String,
          required: true
      },
      vals: {
          type: Array,
          required: true
      },
      columns: {
          type: Array,
          required: true
      },
      filters: {
          type: Array,
          required: true
      },
      select: {
          type: Array,
          required: true
      },
      showTotalCol : {
        type: Boolean,
        required: true
      },
      showTotalRow : {
        type: Boolean,
        required: true
      }
  },


  data () {
    return {
        saveDialog: false,

        operation: "Nenhuma",
        valueFilter: {},

        users: [],
        admins: [],
        selectAdmin: [],
        selectUser: [],
        selectAdminUnchanged: [],
        selectUserUnchanged: [],
        adminUserGroup: null,
        userGroup: null,

        step: "1",

        reportSave: {
            id: null,
            description: "",
            number: null,
            title: null,
            text: null,
            format: null,
            pivotTable: {
                option: null,
                operation: null,
                renderer: null,
                aggregator: null,
                vals: [],
                headers: [],
                cols: [],
                rows: [],
                filters: []
            }
        },

        loadingCheckDescription: false,
        checkedDescription: '',

        data: [],
        attributes: [],
        loading: false,

        //save reports update
        verifiedReportDescription: 0,
        savingReport: false,

        loadingFilters: [],
        
        filterUserCostCenter: false,
    }
  },

  computed: {
      ...mapGetters('auth', ['getUser']),

      endpoint() {
          return [this.$ipSalesPlanning, "sales-planning"]
      },

      endpointDynamicReport() {
          return [this.$ipDynamicReport, "dynamic-report"]
      },

      endpointSaveReport() {
          return [this.$ipDynamicReport, "dynamic-report-slide"]
      },

      endpointUser() {
         return [this.$ipUser, "user"]
      },

      endpointSecurity() {
        return [this.$ipSecurity, "user-group"]
      },

      reportDescriptionHint() {
            let hint = ''

            if (this.verifiedReportDescription !== 0 && this.reportSave.description.length > 0) {
                if (this.verifiedReportDescription === -1) {
                    hint = "Está descrição já está em uso"
                } else if (this.verifiedReportDescription === -2 && this.reportSave.description.length > 0) {
                    hint = "Verifique novamente a descrição"
                } else {
                    hint = "Tudo certo!"
                }
            } else {
                hint = ''
            }

            return hint
        },
        

        disableSaveReportButton() {
            let disabled = false

            if ((this.step === '1' || this.step === 1) && (!this.reportSave.description.length || this.verifiedReportDescription !== 1)) {
                disabled = true
            }

            if ((this.step === '2' || this.step === 2) && (!this.reportSave.number || !this.reportSave.title)) {
                disabled = true
            }

            return disabled
        },
  },

  watch: {
    step (value) {
        if (value === 3) {
            this.reportSave.id ? this.listUserGroup(this.reportSave.id) : false
        }
    },
  },

  created () {
    this.reportSave = this.report
    this.filterUserCostCenter = this.filterUserCostCenterProp
    this.getUsers()
  },

  methods: {
    closeSaveDialog() {
        this.savingReport = false
        this.saveDialog = false
        this.step = '1'
        //para impedir que o close limpe caso já tenha report aberto
        if (!this.reportSave.id){
            this.selectAdmin = []
            this.selectUser = []
            this.admins = []
            this.users = []
            this.report = {
                id: null,
                description: "",
                number: null,
                title: null,
                text: null,
                format: null,
                pivotTable: {}
            }
            this.verifiedReportDescription = 0
        }
    },

    async getUsers() {
        try {
            const res = await this.$http.post(this.$ipUser + "user/list", {})
            if (res) {
                let users = res.data.rows.sort((a, b) => {
                    let nameA = a.name.toLowerCase(),
                        nameB = b.name.toLowerCase()

                    if (nameA < nameB) return -1
                    if (nameA > nameB) return 1
                    return 0 
                })

                if (!this.selectedReport && !this.$route.query.report) {
                    const currentUser = users.find(u => u.id_user === this.getUser.id_user)
                    this.users = users.filter(u => u.id_user !== currentUser.id_user)
                    this.selectAdmin.push(currentUser.id_user)
                } else {
                    this.users = users
                }
                this.admins = users
                // console.log(this.selectAdmin)
            }
        } catch (err) {
            this.$fnError(err)
        }
    },

    async verifyReportDescription (){
        let payload = {
            filter: {
                conditions: [
                    {
                        AndOr: "AND",
                        column: "dynamic_report",
                        operator: "=",
                        value: `${this.reportSave.description}`
                    }
                ]
            }
        }

        if (!this.reportSave.id) {
            this.loadingCheckDescription = true
            try {
                const res = await this.$http.post(this.$ipDynamicReport + 'dynamic-report/list-options', payload)
                if (res) {
                    if (res.data.rows.length > 0) {
                        this.loadingCheckDescription = false
                        return this.verifiedReportDescription = -1
                    } else {
                        this.checkedDescription = this.reportSave.description
                        this.loadingCheckDescription = false
                        return this.verifiedReportDescription = 1
                    }
                }
            } catch (err) {
                this.loadingCheckDescription = false
                this.$fnError(err)
            }
        } else {
            return this.verifiedReportDescription = 1
        }
    },

    async listUserGroup (report) {
        
        this.selectAdmin = []
        this.selectUser = []

        const payload = {
            filter: {
                conditions:[
                    {
                        AndOr: "AND",
                        column: "id_dynamic_report",
                        operator: "=",
                        value: report,
                    }
                ]
            }
        }

        try {
            let res = await this.$http.post(
                this.endpointDynamicReport[0] + "dynamic-report-user-group" + "/list-user",
                payload
            )

            if (res) {
                let users = res.data.rows
                users.forEach((user) => {
                    if (user.admin === 1) {
                        this.selectAdmin.push(user)
                        this.adminUserGroup = user.id_group
                    } else {
                        this.selectUser.push(user)
                        this.userGroup = user.id_group
                    }
                })
                this.selectAdminUnchanged = this.selectAdmin
                this.selectUserUnchanged = this.selectUser
            }
        } catch (err) {
            this.$fnError(err)
        }
    },

    async saveUserGroup (option) {
        try {
            let group =  []
            let groupFormatted = []
            option === 1 ? group = this.selectAdmin : group = this.selectUser
            group.forEach((user) => {
                groupFormatted.push({
                    id_user: user
                })
            })
            const payload = {
                id_dynamic_report: this.reportSave.id,
                admin: option,
                users: groupFormatted
            }

            let res = await this.$http.post(
                this.endpointDynamicReport[0] + 'dynamic-report-user-group' + "/create-report-group",
                payload
            )
            if (res) {
                this.users = res.data.rows
            }
        } catch (err) {
            this.$fnError(err)
        }
    },

    async editUserGroup () {
        let toCreate = []
        let toEdit = []
        let toDelete = []
        let admins = []
        let users = []
        this.selectAdmin.forEach((admin) => {
            if (typeof admin == 'number') {
                admins.push({ id_user: admin })
            }
        })
        this.selectUser.forEach((user) => {
            if (typeof user == 'number') {
                users.push({ id_user: user })
            }
        })
        typeof this.selectAdmin[0] == 'number' ? this.selectAdmin = admins : false
        typeof this.selectUser[0] == 'number' ? this.selectUser = users : false
        //Array select: array de IDs - Array Unchanged: array de objetos
        //preencher o toEdit e o toCreate
        for (var cont = 0; cont < this.selectAdmin.length; cont++) {
            let index = this.selectAdminUnchanged.findIndex((val) => val.id_user  === this.selectAdmin[cont].id_user)
            if (index > -1) {
                toEdit.push({
                    id_user: this.selectAdmin[cont].id_user,
                    id_group: this.adminUserGroup,
                    id_user_group: this.selectAdminUnchanged.find(admin => admin.id_user === this.selectAdmin[cont].id_user).id_user_group
                })
            } else {
                toCreate.push({
                    id_user: this.selectAdmin[cont].id_user,
                    id_group: this.adminUserGroup
                })
            }
        }
        if (this.userGroup) {
            for (cont = 0; cont < this.selectUser.length; cont++) {
                let index = this.selectUserUnchanged.findIndex((val) =>  val.id_user  === this.selectUser[cont].id_user)
                if (index > -1) {
                    toEdit.push({
                        id_user: this.selectUser[cont].id_user,
                        id_group: this.userGroup,
                        id_user_group:  this.selectUserUnchanged.find(admin => admin.id_user === this.selectUser[cont].id_user).id_user_group
                    })
                } else {
                    toCreate.push({
                        id_user: this.selectUser[cont].id_user,
                        id_group: this.userGroup
                    })
                }
            }
        } else {
            let users = []
            this.selectUser.forEach((user) => {
                users.push(user.id_user)
            })
            this.selectUser = users
            this.selectUser.length > 0 ? await this.saveUserGroup(0) : false
        }
        //preencher o toDelete
        for (cont = 0; cont < this.selectAdminUnchanged.length; cont++) {
            let index = this.selectAdmin.findIndex((val) => val.id_user === this.selectAdminUnchanged[cont].id_user)
            if (index < 0) {
                toDelete.push({
                    id_user_group: this.selectAdminUnchanged[cont].id_user_group,
                })
            }
        }
        for (cont = 0; cont < this.selectUserUnchanged.length; cont++) {
            let index = this.selectUser.findIndex((val) => val.id_user === this.selectUserUnchanged[cont].id_user)
            if (index < 0) {
                toDelete.push({
                    id_user_group: this.selectUserUnchanged[cont].id_user_group,
                })
            }
        }

        const payload = {}

        if (toCreate.length > 0) payload.create = { items: toCreate }
        if (toEdit.length > 0) payload.edit = { items: toEdit }
        if (toDelete.length > 0) payload.delete = { items: toDelete}

        try {
            if (toCreate.length > 0 || toDelete.length > 0 || toEdit.length > 0) {
                await this.$http.post(
                    this.endpointSecurity[0] + this.endpointSecurity[1] + "/mass-effect",
                    payload
                )
            }
        } catch (err) {
            this.savingReport = false
            this.$fnError(err)
        }
    },

    async saveDescription() {
        if (!this.selectedReport) {
            try {
                const payload = {
                    dynamic_report: this.reportSave.description,
                    report_type: '0',
                    filter_user_cost_center: this.filterUserCostCenter ? 1 : 0
                }
                
                let res = await this.$http.post(
                    this.endpointDynamicReport[0] + this.endpointDynamicReport[1] + "/create",
                    payload
                )
                if (res) {
                    this.reportSave.id = res.data.id[0]
                    // this.selectedReport = this.reportSave.id
                }
            } catch (err) {
                this.$fnError(err)
            }
        } else {
            // if (this.reportSave.description !== this.descriptionCompare) {
                try {
                    const payload = {
                        dynamic_report: this.reportSave.description,
                        report_type: '0',
                        filter_user_cost_center: this.filterUserCostCenter ? 1 : 0
                    }
                    
                    await this.$http.put(
                        this.endpointDynamicReport[0] + this.endpointDynamicReport[1] + "/edit/" + this.selectedReport,
                        payload
                    )
                    this.reportSave.id = this.selectedReport
                } catch (err) {
                    this.$fnError(err)
                }
            // } else {
            //     this.reportSave.id = this.selectedReport
            // }
        }
    },

    changeSelectedAdmin(e) {
        if (!e.includes(this.getUser.id_user)){
            this.selectAdmin.push(this.getUser.id_user)
            this.$toast.error('O criador do relatório deve ser um Admin')
        }
    },

    async saveReport() {
        this.savingReport = true

        await this.saveDescription()

        this.filters.forEach((filter) => {
            filter.items = []
        })

        if (!this.selectedSlide){
            this.reportSave.pivotTable = {
                events: this.select,
                option: this.option,
                selectedYear: this.selectedYear,
                operation: this.operation,
                // data: this.data,
                renderer: this.rendererName,
                aggregator: this.aggregatorName,
                attributes: this.attributes,
                // vals: [this.selectedVals[0], this.selectedVals[1]],
                vals: this.vals,
                headers: this.columns,
                cols: this.cols,
                rows: this.rows,
                filters: this.valueFilter,
                filterColumn: this.filters,
                showTotalRow: this.showTotalRow,
                showTotalCol: this.showTotalCol
            }
            this.reportSave.pivotTable = JSON.stringify(this.reportSave.pivotTable)
            try {
                const payload = {
                        slide_sequence: this.reportSave.number,
                        slide_title: this.reportSave.title,
                        slide_text: this.reportSave.text,
                        slide_css: this.reportSave.format,
                        pivot_params_json: this.reportSave.pivotTable,
                        id_dynamic_report: this.reportSave.id
                }

                await this.saveUserGroup(1)
                if (this.selectUser.length > 0) {
                    await this.saveUserGroup(0)
                }

                // for(let times = 0; times <= 5; times++) {
                //     await this.$http.post(
                //     this.endpointSaveReport[0] + this.endpointSaveReport[1] + "/create",
                //     payload
                //     )
                // }

                let res = await this.$http.post(
                    this.endpointSaveReport[0] + this.endpointSaveReport[1] + "/create",
                    payload
                )
                if (res) {
                    this.$toast.success(res.data.msg)
                    this.$emit('reportSaved', { id_report: this.reportSave.id , id_slide: res.data.id[0] })
                }
            } catch (err) {
                this.$emit('closeDialog')
                this.$fnError(err)
            }
        } else {
            this.editSlide()
        }
    },

    async editSlide () {
        //console.log(this.vals)
        this.reportSave.pivotTable = {
            events: this.select,
            option: this.option,
            selectedYear: this.selectedYear,
            operation: this.operation,
            // data: this.data,
            renderer: this.rendererName,
            aggregator: this.aggregatorName,
            attributes: this.attributes,
            vals: this.vals,
            headers: this.columns,
            cols: this.cols,
            rows: this.rows,
            filters: this.valueFilter,
            filterColumn: this.filters,
            showTotalRow: this.showTotalRow,
            showTotalCol: this.showTotalCol
        }
        //console.log(this.reportSave.pivotTable)
        this.reportSave.pivotTable = JSON.stringify(this.reportSave.pivotTable)
        try {
            
            this.editUserGroup()

            const payload = {
                slide_sequence: this.reportSave.number,
                slide_title: this.reportSave.title,
                slide_text: this.reportSave.text,
                slide_css: this.reportSave.format,
                pivot_params_json: this.reportSave.pivotTable,
                id_dynamic_report: this.reportSave.id
            }
            
            let res = await this.$http.put(
                this.endpointSaveReport[0] + this.endpointSaveReport[1] + "/edit/" + this.selectedSlide,
                payload
            )
            if (res) {
                this.$toast.success(res.data.msg)
                this.$emit('reportSaved', { id_report: this.reportSave.id , id_slide: res.data.id[0] })
            }
            this.saveDialog = false
            this.savingReport = false
            // this.getData()
            this.step = "1"
        } catch (err) {
            this.saveDialog = false
            this.savingReport = false
            this.step = "1"
            this.$fnError(err)
        }
    },
  }
}
</script>
