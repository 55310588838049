<template>
  <v-card style="position: relative;">
      <v-toolbar
          dark
          flat
          :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
          style="position: sticky; top: 0; width: 100%; z-index: 1000;" 
      >
          <v-toolbar-title>Salvar novo slide</v-toolbar-title>
          <v-spacer />

          <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                  <v-btn color="transparent" class="mb-2 mt-2"
                      v-on="on"
                      min-width="48px"
                      @click="$emit('closeNewReport')"
                      depressed
                  >
                      <v-icon class="white--text">mdi-close</v-icon>
                  </v-btn>
              </template>
              <span>Fechar</span>
          </v-tooltip>
      </v-toolbar>

      <v-container>
          <p class="subtitle-1 font-weight-regular">
              Descrição do relatório: {{ report.description }}
          </p>
          <v-layout align-center justify-center wrap class="my-4">
              <v-flex xs12>
                  <v-text-field
                      :value="reportNewSlide.number"
                      @input=" v => reportNewSlide.number = v"
                      max-length="11"
                      class="mx-2 my-1"
                      label="Número sequencial do slide"
                      :hint="`Este relatório já possui ${slides} slide(s)`"
                      persistent-hint
                  ></v-text-field>
              </v-flex>
              <v-flex xs12>
                  <v-text-field
                      :value="reportNewSlide.title"
                      @input=" v => reportNewSlide.title = v"
                      max-length="250"
                      class="mx-2 my-1"
                      label="Título do slide"
                  ></v-text-field>
              </v-flex>
          </v-layout>
      </v-container>
      <v-divider class="mt-1 mb-1"></v-divider>

      <div class="light" style="position: sticky; bottom: 0; width: 100%;">
          <v-card-actions class="px-6 py-6">
              <v-spacer></v-spacer>
              <v-btn @click="$emit('closeNewReport')" text class="primary--text">
                  Cancelar
              </v-btn>
              <v-btn
                  color="primary"
                  @click="newSlide(report.id), newDialog = false"
                  :disabled="reportNewSlide.number.length < 1 || reportNewSlide.title.length < 1 "
              >
                  <span> Salvar </span>
              </v-btn>
          </v-card-actions>
      </div>
  </v-card>
</template>
<script>
export default {
  props: {
    report: {
      type: Object,
      required: true
    },
    slides: {
      type: Number,
      required: true
    },
    option: {
        type: String,
        required: true
    },
    selectedYear: {
        type: String,
        required: true
    },
    cols: {
        type: Array,
        required: true
    },
    rows: {
        type: Array,
        required: true
    },
    rendererName: {
        type: String,
        required: true
    },
    aggregatorName: {
        type: String,
        required: true
    },
    vals: {
        type: Array,
        required: true
    },
    columns: {
        type: Array,
        required: true
    },
    filters: {
        type: Array,
        required: true
    },
    valueFilter: {
        type: Object,
        required: true
    },
    select: {
        type: Array,
        required: true
    },
    showTotalCol : {
      type: Boolean,
      required: true
    },
    showTotalRow : {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      reportNewSlide: {
        number: '',
        title: '',
        text: null,
        format: null,
        pivotTable: {
            option: null,
            operation: null,
            renderer: null,
            aggregator: null,
            vals: [],
            headers: [],
            cols: [],
            rows: [],
            filters: []
        }
      }
    }
  },

  computed: {
      endpointSaveReport() {
          return [this.$ipDynamicReport, "dynamic-report-slide"]
      },
  },

  methods: {
    async newSlide () {

        this.report.pivotTable = {
            events: this.select,
            option: this.option,
            selectedYear: this.selectedYear,
            operation: this.operation,
            // data: this.data,
            renderer: this.rendererName,
            aggregator: this.aggregatorName,
            attributes: this.attributes,
            vals: this.vals,
            headers: this.columns,
            cols: this.cols,
            rows: this.rows,
            filters: this.valueFilter,
            filterColumn: this.filters,
            showTotalRow: this.showTotalRow,
            showTotalCol: this.showTotalCol
        }
        this.report.pivotTable = JSON.stringify(this.report.pivotTable)

        try {
            const payload = {
                slide_sequence: this.reportNewSlide.number,
                slide_title: this.reportNewSlide.title,
                slide_text: this.reportNewSlide.text,
                slide_css: this.reportNewSlide.format,
                pivot_params_json: this.report.pivotTable,
                id_dynamic_report: this.report.id
            }

            let res = await this.$http.post(
                this.endpointSaveReport[0] + this.endpointSaveReport[1] + "/create",
                payload
            )
            if (res) {
                this.$toast.success(res.data.msg)
                this.$emit('reportSaved', { id_report: this.report.id , id_slide: res.data.id[0] })
            }
        } catch (err) {
            this.$fnError(err)
        }
    },
  }
}
</script>
